/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, CardContent, CircularProgress, Grid } from "@mui/material";
import CompanyInfo from "../CompanyInfo";
import { useEffect, useState } from "react";
import axios from "axios";
import Item from "./Item";
import REACT_APP from "../../../environment.js";
import { toast } from "react-toastify";

export default function Policy({ setStep, companyInfo, token }) {
    const [waiting, setWaiting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [policies, setPolicies] = useState([]);
    useEffect(() => {
        getPolicies();
    }, []);

    const getPolicies = async () => {
        setLoading(true);
        axios
            .get(`${REACT_APP.API_URL}/api/hrp/v2/company/first/policies`, {
                headers: { Authorization: token },
            })
            .then((res) => {
                setLoading(false);
                setPolicies(res.data.data);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    async function submit() {
        setWaiting(true);
        const data = policies.filter((e) => document.getElementById("app" + e.id).checked === true).map((e) => e.id);
        if (data.length === 0)
            await axios
                .post(`${REACT_APP.API_URL}/api/hrp/v2/company/first/policy/skip`, {}, { headers: { Authorization: token } })
                .then(() => setStep("get_started"))
                .catch((err) => {
                    console.log(err);
                    toast.error("An error occurred!");
                });
        else
            await axios
                .post(`${REACT_APP.API_URL}/api/hrp/v2/company/first/policy`, data, { headers: { Authorization: token } })
                .then(() => setStep("get_started"))
                .catch((err) => {
                    console.log(err);
                    toast.error("An error occurred!");
                });
        setWaiting(false);
    }

    return (
        <>
            <div className="d-flex justify-content-center flex-column container py-3">
                <div className="py-2">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="text-center">Applications</h5>
                        </div>
                        <div>
                            <Card>
                                <CardContent style={{ padding: "24px" }}>
                                    <CompanyInfo info={companyInfo} />
                                    {loading ? (
                                        <div className="w-100 all__center py-5 my-5">
                                            <CircularProgress />
                                        </div>
                                    ) : (
                                        <div>
                                            <h5>Which apps do you want to use?</h5>
                                            {policies.map((e) => (
                                                <Item key={e.id} info={e} />
                                            ))}
                                        </div>
                                    )}
                                    <div className="l-page">
                                        <Grid item xs={12}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                className="float-end"
                                                onClick={submit}
                                                disabled={waiting || loading}
                                                startIcon={waiting && <CircularProgress size="0.9rem" />}
                                            >
                                                Next
                                            </Button>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
