/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, CardContent, CircularProgress, Grid } from "@mui/material";
import REACT_APP from "../../../environment.js";
import CompanyInfo from "../CompanyInfo";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "./input.css";
import regex from "tts-hrp-val-lib";
const id =
    ((Math.floor(Math.random() * 10) % 9) + 1).toString() +
    Math.floor(Math.random() * 10) +
    Math.floor(Math.random() * 10) +
    ((Math.floor(Math.random() * 10) % 9) + 1);

export default function SetEmployeeId({ setStep, companyInfo, token }) {
    const [submitting, setSubmitting] = useState(false);
    const [empId, setEmpId] = useState(companyInfo.name.slice(0, 1)?.toLowerCase() + "a");
    const [inval, setinval] = useState(empId);
    const [check, setcheck] = useState(false);

    useEffect(() => {
        (async () => {
            await checkEID();
        })();
    }, [empId]);

    async function checkEID() {
        setSubmitting(true);
        axios
            .get(`${REACT_APP.API_URL}/api/hrp/v2/company/first/employeeid/check?employee_id=${empId}`, { headers: { Authorization: token } })
            .then((res) => {
                setcheck(res.data.data);
                setSubmitting(false);
            })
            .catch((err) => {
                setSubmitting(false);
                console.log(err);
                toast.error("An error occurred!");
            });
    }
    function submit() {
        setSubmitting(true);
        axios
            .post(`${REACT_APP.API_URL}/api/hrp/v2/company/first/employeeid`, { employee_id: empId + id }, { headers: { Authorization: token } })
            .then(() => {
                setStep("logo");
                setSubmitting(false);
            })
            .catch((err) => {
                setSubmitting(false);
                console.log(err);
                toast.error("An error occurred!");
            });
    }
    return (
        <>
            <div className="d-flex justify-content-center flex-column container py-3">
                <div className="py-2">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="text-center">Generate ID of Employees</h5>
                        </div>

                        <div>
                            <Card>
                                <CardContent style={{ padding: "24px" }}>
                                    <CompanyInfo info={companyInfo} />
                                    <div>
                                        <div
                                            className="eidg-container"
                                            onClick={() => {
                                                document.getElementById("eidg").focus();
                                            }}
                                        >
                                            <input
                                                maxLength="2"
                                                id="eidg"
                                                value={inval}
                                                onChange={(e) => {
                                                    setcheck(false);
                                                    if (regex.lower_case.test(e.target.value)) {
                                                        setinval(e.target.value);
                                                        if (e.target.value.length === 2) setEmpId(e.target.value);
                                                    }
                                                }}
                                            />
                                            <span>
                                                {id}
                                                {!regex.employee_id_symbols.test(inval) ? (
                                                    <span style={{ color: "red" }}>enter two lowercase letters</span>
                                                ) : submitting ? (
                                                    <span style={{ color: "red" }}>please wait</span>
                                                ) : !check ? (
                                                    <span style={{ color: "red" }}>such employee id is already used</span>
                                                ) : (
                                                    <span style={{ color: "green" }}>can be used</span>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="l-page">
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" className="float-end" disabled={!check || submitting} onClick={submit}>
                                                {submitting && <CircularProgress size="0.9rem" />}
                                                Next
                                            </Button>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
