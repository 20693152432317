/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, CardContent, CircularProgress, Grid } from "@mui/material";
import CompanyInfo from "../CompanyInfo.jsx";
import { useEffect, useState } from "react";
import axios from "axios";
import REACT_APP from "../../../environment.js";
import { toast } from "react-toastify";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function Payroll({ setStep, companyInfo, token }) {
    const [waiting, setWaiting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [frequencies, setFrequencies] = useState([]);
    const [frequency, setFrequency] = useState(1);

    useEffect(() => {
        getPayrollFrequencies();
    }, []);

    const getPayrollFrequencies = async () => {
        setLoading(true);
        axios
            .get(`${REACT_APP.API_URL}/api/hrp/v2/company/first/payroll/frequency`, {
                headers: { Authorization: token },
            })
            .then((res) => {
                setLoading(false);
                setFrequencies(res.data.data);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const handleChange = (e) => setFrequency(e.target.value);

    async function submit() {
        setWaiting(true);
        await axios
            .post(`${REACT_APP.API_URL}/api/hrp/v2/company/first/payroll`, { frequency }, { headers: { Authorization: token } })
            .then(() => setStep("policy"))
            .catch((err) => {
                console.log(err);
                toast.error("An error occurred!");
            });
        setWaiting(false);
    }
    return (
        <>
            <div className="d-flex justify-content-center flex-column container py-3">
                <div className="py-2">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="text-center">Payroll</h5>
                        </div>
                        <div>
                            <Card>
                                <CardContent style={{ padding: "24px" }}>
                                    <CompanyInfo info={companyInfo} />
                                    {loading ? (
                                        <div className="w-100 all__center py-5 my-5">
                                            <CircularProgress />
                                        </div>
                                    ) : (
                                        <div>
                                            <FormControl>
                                                <FormLabel id="currency">Select payroll frequency</FormLabel>
                                                <RadioGroup aria-labelledby="currency" name="currency" value={frequency} onChange={handleChange}>
                                                    {frequencies.map((e) => (
                                                        <FormControlLabel key={e.id} value={e.id} control={<Radio />} label={e.name} />
                                                    ))}
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    )}
                                    <div className="l-page">
                                        <Grid item xs={12}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                className="float-end"
                                                onClick={submit}
                                                disabled={waiting || loading}
                                                startIcon={waiting && <CircularProgress size="0.9rem" />}
                                            >
                                                Next
                                            </Button>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
