import React, { useEffect } from "react";
import REACT_APP from "../environment.js";

export default function Forward() {
    useEffect(() => {
        window.onbeforeunload = {};
        window.location.replace(REACT_APP.TRANSOXANIA);
    }, []);
    return <div></div>;
}
