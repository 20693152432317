import { Checkbox, FormControlLabel } from "@mui/material";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import WidgetsIcon from "@mui/icons-material/Widgets";

export default function Item({ info }) {
    function switchApp(app) {
        switch (app) {
            case "ilearning":
                return (
                    <>
                        <LocalLibraryIcon />
                        <b> {app.toUpperCase()}</b>
                    </>
                );
            case "ilearn":
                return (
                    <>
                        <LocalLibraryIcon />
                        <b> {app.toUpperCase()}</b>
                    </>
                );
            case "iLearning":
                return (
                    <>
                        <LocalLibraryIcon />
                        <b> {app.toUpperCase()}</b>
                    </>
                );
            case "iLearn":
                return (
                    <>
                        <LocalLibraryIcon />
                        <b> {app.toUpperCase()}</b>
                    </>
                );
            case "timesheet":
                return (
                    <>
                        <EventAvailableIcon />
                        <b>{app.toUpperCase()}</b>
                    </>
                );
            case "TimeSheet":
                return (
                    <>
                        <EventAvailableIcon />
                        <b>{app.toUpperCase()}</b>
                    </>
                );
            case "bgd":
                return (
                    <>
                        <PersonSearchIcon />
                        <b>{app.toUpperCase()}</b>
                    </>
                );
            case "BGD":
                return (
                    <>
                        <PersonSearchIcon />
                        <b>{app.toUpperCase()}</b>
                    </>
                );

            default:
                return (
                    <>
                        <WidgetsIcon />
                        <b>{app.toUpperCase()}</b>
                    </>
                );
        }
    }
    return (
        <div>
            <FormControlLabel control={<Checkbox name="checkhead" defaultChecked={false} id={"app" + info.id} />} label={switchApp(info.policy)} />
        </div>
    );
}
