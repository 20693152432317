export default function appURL(requester) {
    const result = requester?.replace(/\/?\b(logout|login)\b/i, "");

    /* localhost uchun */
    if (/localhost:9999/.test(requester)) return result;
    if (/localhost:9998/.test(requester)) return result;
    if (/localhost:9997/.test(requester)) return result;

    /* live server uchun */
    if (/127.0.0.1:5500/.test(requester)) return result;

    /* dev-environment uchun */
    if (/143.244.173.81:9002/.test(requester)) return result;
    if (/143.244.173.81:9003/.test(requester)) return result;
    if (/143.244.173.81:9001/.test(requester)) return result;

    /* qa-environment uchun */
    if (/104.248.193.89:9001/.test(requester)) return result;
    if (/104.248.193.89:9002/.test(requester)) return result;
    if (/104.248.193.89:9003/.test(requester)) return result;
    if (/admin-qa.ishkuni.uz/.test(requester)) return result;
    if (/tms-qa.ishkuni.uz/.test(requester)) return result;
    if (/hrp-qa.ishkuni.uz/.test(requester)) return result;

    /* prod-environment uchun */
    if (/159.223.217.246:9201/.test(requester)) return result;
    if (/159.223.217.246:9202/.test(requester)) return result;
    if (/159.223.217.246:9203/.test(requester)) return result;
    if (/admin.ishkuni.uz/.test(requester)) return result;
    if (/tms.ishkuni.uz/.test(requester)) return result;
    if (/hrp.ishkuni.uz/.test(requester)) return result;
    return result;
}
