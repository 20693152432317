/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import REACT_APP from "../../../environment.js";
import { Button, Card, CardContent, CircularProgress, Grid } from "@mui/material";
import CompanyInfo from "../CompanyInfo";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function Branch({ setStep, companyInfo, token }) {
    const [isBranch, setIsBranch] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const handleChange = (e) => setIsBranch(e.target.value);

    async function submit() {
        setWaiting(true);
        await axios
            .post(`${REACT_APP.API_URL}/api/hrp/v2/company/first/branch`, { branch_presence: isBranch }, { headers: { Authorization: token } })
            .then(() => setStep("days"))
            .catch((err) => {
                console.log(err);
                toast.error("An error occurred!");
            });
        setWaiting(false);
    }

    return (
        <>
            <div className="d-flex justify-content-center flex-column container py-3">
                <div className="py-2">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="text-center">Branch Presence</h5>
                        </div>
                        <div>
                            <Card>
                                <CardContent style={{ padding: "24px" }}>
                                    <CompanyInfo info={companyInfo} />
                                    <div>
                                        <FormControl>
                                            <FormLabel id="branch">Does your company have branches?</FormLabel>
                                            <RadioGroup aria-labelledby="branch" name="branch" value={isBranch} onChange={handleChange}>
                                                <FormControlLabel value={true} control={<Radio />} label={"Yes"} />
                                                <FormControlLabel value={false} control={<Radio />} label={"No"} />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div className="l-page">
                                        <Grid item xs={12}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                className="float-end"
                                                onClick={submit}
                                                disabled={waiting}
                                                startIcon={waiting && <CircularProgress size="0.9rem" />}
                                            >
                                                Next
                                            </Button>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
