/* eslint-disable no-useless-escape */
import regex from "tts-hrp-val-lib";
import { object, string } from "yup";

export const addressSchema = object({
    country: string().matches({ message: "Invalid country" }).required("Country is required"),
    region: string().matches(regex.region, { message: "Invalid region" }).required("Region is required"),
    city: string().matches(regex.city, { message: "Invalid city" }).required("City is required"),
    address1: string().matches(regex.address1, { message: "invalid address" }).required("Address1 is required"),
    address2: string().matches(regex.address2, { message: "invalid address" }).nullable(),
    zip_code: string().matches(regex.zip_code, { message: "invalid zip code" }).nullable(),
});
