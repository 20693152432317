/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import REACT_APP from "../../../environment.js";
import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, MenuItem } from "@mui/material";
import { Grid } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { TextField } from "formik-material-ui";
import { Field, Form, Formik } from "formik";
import { addressSchema } from "./validateSchema";
import { toast } from "react-toastify";
import axios from "axios";
const address = {
    country: undefined,
    region: undefined,
    city: undefined,
    address1: undefined,
    address2: undefined,
    zip_code: undefined,
};

export default function Address({ setStep, token }) {
    const [country, setcountry] = useState([]);
    const [regions, setregions] = useState([]);
    useEffect(() => {
        const getCountry = async () => {
            await axios(`${REACT_APP.COMMON_API}/tts/api/v01/countries`, {
                headers: {
                    "client-id": REACT_APP.COMMON_ID,
                    secret: REACT_APP.COMMON_SECRET,
                },
            })
                .then((res) => setcountry(res.data.data))
                .catch((err) => {
                    console.log(err);
                    toast.error("An error occurred!");
                });
        };
        const getRegions = async () => {
            await axios(`${REACT_APP.COMMON_API}/tts/api/v01/region/14ed6cfc-d787-45ba-b44d-84ac06e37935`, {
                headers: {
                    "client-id": REACT_APP.COMMON_ID,
                    secret: REACT_APP.COMMON_SECRET,
                },
            })
                .then((res) => setregions(res.data.data))
                .catch((err) => {
                    console.log(err);
                    toast.error("An error occurred!");
                });
        };
        (async () => {
            await getCountry();
            await getRegions();
        })();
    }, []);

    const submit = async (data, setSubmitting) => {
        if (!data.address2) data.address2 = null;
        if (!data.zip_code) data.zip_code = null;

        await axios
            .post(`${REACT_APP.API_URL}/api/hrp/v2/company/first/address`, data, { headers: { Authorization: token } })
            .then(() => setStep("employee_id"))
            .catch((err) => {
                console.log(err);
                toast.error("An error occurred!");
            });
        setSubmitting(false);
    };

    return (
        <>
            {
                <div className="d-flex justify-content-center flex-column container py-3">
                    <div className="py-2">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="text-center">Address</h5>
                            </div>
                            <div>
                                <Card>
                                    <CardContent style={{ padding: "24px" }}>
                                        <Formik initialValues={address} validationSchema={addressSchema} onSubmit={submit}>
                                            {({ values, errors, touched, isSubmitting }) => (
                                                <Form autoComplete="off">
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item container spacing={2} xs={12}>
                                                            <Grid item xs={12} sm={12}>
                                                                <label>Main address of the company</label>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <Field
                                                                    fullWidth
                                                                    select
                                                                    variant="standard"
                                                                    component={TextField}
                                                                    disableCloseOnSelect
                                                                    onClick={() => (values.region = undefined)}
                                                                    name="country"
                                                                    label="Select Country"
                                                                >
                                                                    {country.map((el) => (
                                                                        <MenuItem key={el.id} value={el.name}>
                                                                            {el.native_name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Field>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                {values.country === "Uzbekistan" ? (
                                                                    <Field
                                                                        fullWidth
                                                                        select
                                                                        variant="standard"
                                                                        component={TextField}
                                                                        name="region"
                                                                        label="Select Region"
                                                                    >
                                                                        {regions.map((el) => (
                                                                            <MenuItem key={el.id} value={el.name}>
                                                                                {el.native_name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Field>
                                                                ) : (
                                                                    <Field fullWidth variant="standard" name="region" component={TextField} label="Region" />
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <Field fullWidth variant="standard" name="city" component={TextField} label="City / Village" />
                                                            </Grid>

                                                            <Grid item xs={12} sm={4}>
                                                                <Field fullWidth variant="standard" name="address1" component={TextField} label="Address 1" />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <Field fullWidth variant="standard" name="address2" component={TextField} label="Address 2" />
                                                            </Grid>

                                                            <Grid item xs={12} sm={4}>
                                                                <Field fullWidth variant="standard" name="zip_code" component={TextField} label="Zipcode" />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    type="submit"
                                                                    variant="contained"
                                                                    className="float-end"
                                                                    disabled={isSubmitting}
                                                                    startIcon={isSubmitting && <CircularProgress size="0.9rem" />}
                                                                >
                                                                    Next
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Form>
                                            )}
                                        </Formik>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
