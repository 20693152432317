/* eslint-disable react-hooks/exhaustive-deps */
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Login from "./components/Login";
import { useEffect, useState } from "react";
import "./App.css";
import appURL from "./assets/requesterURL";
import getQueryParam from "./assets/getQueryParam";
import Forward from "./components/Forward";
import SpinnerTTS from "./components/spinner/Spinner";
import FirstTimeCompany from "./components/FirstTimeCompany";
import axios from "axios";
import loginWithTokenURL from "./assets/loginTokenAPI";
import REACT_APP from "./environment.js";

function App() {
    console.log(process.env.NODE_ENV);
    const [err, setErr] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const ftc = getQueryParam("company", window.location.search) || localStorage.getItem("ftc");
        if (/ftc/.test(window.location.pathname)) {
            localStorage.clear();
            localStorage.setItem("ftc", ftc);
            localStorage.setItem("url", REACT_APP.ADMIN_UI);
            setTimeout(() => {
                setErr(true);
            }, 1500);
        } else {
            const url = getQueryParam("url", window.location.search) || localStorage.getItem("url");
            const reply = appURL(url);
            if (!reply)
                setTimeout(() => {
                    setErr(false);
                }, 1500);
            else {
                if (/logout/.test(url)) {
                    console.log("logout");
                    localStorage.clear();
                }
                localStorage.setItem("url", reply);
                const token = localStorage.getItem("token");
                if (token) {
                    window.onbeforeunload = {};
                    axios
                        .post(`${loginWithTokenURL(reply)}`, {}, { headers: { Authorization: token } })
                        .then(() => {
                            localStorage.removeItem("url");
                            window.location.replace(`${reply}?user=${token}`);
                        })
                        .catch((err) => {
                            console.log(err);
                            setErr(true);
                            if (err.response.status !== 498) toast.error(err.response.data?.errors[0].message || "Something went wrong");
                        });
                } else
                    setTimeout(() => {
                        setErr(true);
                    }, 1500);
            }
        }
        navigate(`${window.location.pathname}`);
    }, []);

    return (
        <div className="App">
            <ToastContainer />
            {err === true ? (
                <Routes>
                    <Route path="*" element={<Navigate to="/login" />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/ftc" element={<FirstTimeCompany />} />
                </Routes>
            ) : err === false ? (
                <Routes>
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route path="/" element={<Forward />} />
                </Routes>
            ) : (
                <SpinnerTTS />
            )}
        </div>
    );
}

export default App;
