/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react";
import Personal from "./Personal/Personal";
import Logo from "./Logo/Logo";
import "./style.css";
import Policy from "./Policy/Policy";
import Finish from "./Finish/Finish";
import SpinnerTTS from "../spinner/Spinner";
import Address from "./Address/Address";
import SetEmployeeId from "./GenerateEmployeeId/SetEmployeeId";
import Retry from "./Retry";
import Currency from "./Currency/Currency";
import Branch from "./BranchPresence/BranchPresence";
import CompanyDays from "./CompanyDays/CompanyDays";
import REACT_APP from "../../environment.js";
import Payroll from "./Payroll/Payroll.jsx";

const Index = () => {
    const [step, setStep] = useState(null);
    const [ers, seters] = useState(false);
    const [loading, setloading] = useState(true);
    const [token, setToken] = useState(localStorage.getItem("ftc") || getQueryParam("token"));
    const [companyInfo, setCompanyInfo] = useState(null);

    useEffect(() => {
        let stoken = localStorage.getItem("ftc");
        if (stoken) setToken(stoken);
        else {
            let query = getQueryParam("token");
            setToken(query);
            localStorage.setItem("ftc", query);
        }
        getStep();
    }, []);

    useEffect(() => {
        getCompanyInfo();
    }, [step]);

    const getStep = async () => {
        axios
            .get(`${REACT_APP.API_URL}/api/hrp/v2/company/first/step`, {
                headers: { Authorization: token },
            })
            .then((res) => {
                if (res.data.data.step === "one") {
                    window.onbeforeunload = {};
                    window.location = "/login";
                    return 0;
                }
                if (companyInfo) setloading(false);
                setStep(res.data.data.step);
            })
            .catch((err) => {
                seters(true);
                console.log(err);
            });
    };

    const getCompanyInfo = async () => {
        axios
            .get(`${REACT_APP.API_URL}/api/hrp/v2/company/first/info`, {
                headers: { Authorization: token },
            })
            .then((res) => {
                if (step) setloading(false);
                setCompanyInfo(res.data.data);
            })
            .catch((err) => {
                if (step) setloading(false);
                seters(true);
                console.log(err);
            });
    };
    return (
        <div className={loading || ers ? "" : "container mt-5"}>
            <>
                {ers ? (
                    <Retry />
                ) : loading ? (
                    <SpinnerTTS />
                ) : step === "zero" ? (
                    <Personal setStep={setStep} token={token} />
                ) : step === "address" ? (
                    <Address setStep={setStep} token={token} />
                ) : step === "employee_id" ? (
                    <SetEmployeeId companyInfo={companyInfo} setStep={setStep} token={token} />
                ) : step === "logo" ? (
                    <Logo setStep={setStep} companyInfo={companyInfo} token={token} />
                ) : step === "currency" ? (
                    <Currency setStep={setStep} companyInfo={companyInfo} token={token} />
                ) : step === "branch" ? (
                    <Branch setStep={setStep} companyInfo={companyInfo} token={token} />
                ) : step === "days" ? (
                    <CompanyDays setStep={setStep} companyInfo={companyInfo} token={token} />
                ) : step === "payroll" ? (
                    <Payroll setStep={setStep} companyInfo={companyInfo} token={token} />
                ) : step === "policy" ? (
                    <Policy setStep={setStep} companyInfo={companyInfo} token={token} />
                ) : step === "get_started" ? (
                    <Finish companyInfo={companyInfo} token={token} />
                ) : (
                    <>
                        {seters(true)}
                        <Retry />
                    </>
                )}
            </>
        </div>
    );
};

export default Index;

function getQueryParam(key) {
    const qst = window.location.search;
    return qst.slice(
        qst.indexOf(key + "=") + 6,
        qst.slice(qst.indexOf(key + "=") + 6).indexOf("&") !== -1 ? qst.slice(qst.indexOf(key + "=") + 6).indexOf("&") + 7 : qst.length
    );
}
