/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Button, Card, CardContent, Checkbox } from "@mui/material";
import { FormLabel, Grid, Radio } from "@mui/material";
import { CircularProgress, FormControlLabel } from "@mui/material";
import { RadioGroup, TextField } from "formik-material-ui";
import { Field, Form, Formik } from "formik";
import REACT_APP from "../../../environment.js";
import { personalSchema } from "./validateSchema";
import { toast } from "react-toastify";
import axios from "axios";
const personal = {
    first_name: undefined,
    last_name: undefined,
    middle_name: undefined,
    cell_phone: undefined,
    home_phone: undefined,
    tin: undefined,
    birth_date: undefined,
    nationality: undefined,
    citizenship: undefined,
    gender: undefined,
    password: undefined,
    cpassword: undefined,
};

export default function Personal({ setStep, token }) {
    const [checkhead, setcheckhead] = useState(false);

    const submit = async (data, setSubmitting) => {
        if (!(data.password?.length > 6)) return toast.error("Choose longer password");
        if (data.cpassword !== data.password) return toast.error("Confirm password");
        delete data.cpassword;
        if (!data.middle_name) data.middle_name = null;
        if (!data.tin) data.tin = null;
        if (!data.nationality) data.nationality = null;

        await axios
            .post(`${REACT_APP.API_URL}/api/hrp/v2/company/first/personal`, data, { headers: { Authorization: token } })
            .then(() => setStep("address"))
            .catch((err) => {
                data.cpassword = document.getElementById("cpassword").value;
                console.log(err);
                toast.error("An error occurred!");
            });
        setSubmitting(false);
    };

    return (
        <>
            {
                <div className="d-flex justify-content-center flex-column container py-3">
                    <div className="py-2">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="text-center">Personal</h5>
                            </div>
                            <div>
                                <Card>
                                    <CardContent style={{ padding: "24px" }}>
                                        <Formik initialValues={personal} validationSchema={personalSchema} onSubmit={submit}>
                                            {({ values, errors, touched, isSubmitting }) => (
                                                <Form autoComplete="off">
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item container spacing={2} xs={12}>
                                                            <Grid item xs={12} sm={4}>
                                                                <Field
                                                                    fullWidth
                                                                    name="first_name"
                                                                    component={TextField}
                                                                    variant="standard"
                                                                    label="First Name"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <Field fullWidth name="last_name" component={TextField} variant="standard" label="Last Name" />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <Field
                                                                    fullWidth
                                                                    name="middle_name"
                                                                    component={TextField}
                                                                    variant="standard"
                                                                    label="Middle Name"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <label className="mat_label">Birth Date</label>
                                                                <Field
                                                                    fullWidth
                                                                    type="date"
                                                                    name="birth_date"
                                                                    component={TextField}
                                                                    variant="standard"
                                                                    format="dd/mm/yyyy"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <Field fullWidth name="tin" component={TextField} variant="standard" label="TIN" />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <Field
                                                                    fullWidth
                                                                    name="nationality"
                                                                    component={TextField}
                                                                    variant="standard"
                                                                    label="Nationality"
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} sm={3}>
                                                                <Field
                                                                    fullWidth
                                                                    name="citizenship"
                                                                    component={TextField}
                                                                    variant="standard"
                                                                    label="Citizenship"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={3}>
                                                                <Field
                                                                    fullWidth
                                                                    name="home_phone"
                                                                    component={TextField}
                                                                    variant="standard"
                                                                    label="Home phone"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={3}>
                                                                <Field
                                                                    fullWidth
                                                                    name="cell_phone"
                                                                    component={TextField}
                                                                    variant="standard"
                                                                    label="Cell phone"
                                                                />
                                                            </Grid>
                                                            <Field component={RadioGroup} name="gender" className="ps-sm-3 col-sm-3 mt-2">
                                                                <FormLabel component="legend">Gender</FormLabel>
                                                                <Grid>
                                                                    <FormControlLabel value="male" label="male" control={<Radio disabled={isSubmitting} />} />
                                                                    <FormControlLabel
                                                                        value="female"
                                                                        label="female"
                                                                        control={<Radio disabled={isSubmitting} />}
                                                                    />
                                                                </Grid>
                                                                {touched.gender && errors.gender && <p className="error">{errors.gender}</p>}
                                                            </Field>

                                                            <Grid item xs={12}>
                                                                <p className="text-center mt-3">Password</p>
                                                            </Grid>

                                                            <Grid container item spacing={2}>
                                                                <Grid item container spacing={2} xs={12}>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <Field
                                                                            fullWidth
                                                                            name={"password"}
                                                                            component={TextField}
                                                                            variant="standard"
                                                                            label="Password"
                                                                            type="password"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <Field
                                                                            fullWidth
                                                                            name={"cpassword"}
                                                                            id={"cpassword"}
                                                                            component={TextField}
                                                                            variant="standard"
                                                                            label="Confirm Password"
                                                                            type="password"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={8}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    name="checkhead"
                                                                                    checked={checkhead}
                                                                                    onChange={(e) => {
                                                                                        setcheckhead(e.target.checked);
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label="The above information is related to the head of the company's for HRP platform"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    type="submit"
                                                                    variant="contained"
                                                                    className="float-end"
                                                                    disabled={isSubmitting || !checkhead}
                                                                    startIcon={isSubmitting && <CircularProgress size="0.9rem" />}
                                                                >
                                                                    Next
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Form>
                                            )}
                                        </Formik>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
