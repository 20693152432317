/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import REACT_APP from "../../../environment.js";
import { Button, Card, CardContent, CircularProgress, FormGroup, Grid } from "@mui/material";
import CompanyInfo from "../CompanyInfo";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
export default function CompanyDays({ setStep, companyInfo, token }) {
    const [firstDay, setFirstDay] = useState(1);
    const [waiting, setWaiting] = useState(false);
    const [wd, setWorkDays] = useState({ monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: false, sunday: false });
    const firstDayHandleChange = (e) => setFirstDay(e.target.value);

    function onChange(day) {
        const data = { ...wd };
        data[day] = !wd[day];
        setWorkDays(data);
    }

    async function submit() {
        const data = { first_day: firstDay, ...wd };
        setWaiting(true);
        await axios
            .post(`${REACT_APP.API_URL}/api/hrp/v2/company/first/days`, data, { headers: { Authorization: token } })
            .then(() => setStep("payroll"))
            .catch((err) => {
                console.log(err);
                toast.error("An error occurred!");
            });
        setWaiting(false);
    }

    return (
        <>
            <div className="d-flex justify-content-center flex-column container py-3">
                <div className="py-2">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="text-center">Working days</h5>
                        </div>
                        <div>
                            <Card>
                                <CardContent style={{ padding: "24px" }}>
                                    <CompanyInfo info={companyInfo} />
                                    <div>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item container spacing={2} xs={12}>
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl>
                                                        <FormLabel id="branch">Working days</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={<Checkbox defaultChecked onClick={() => onChange("monday")} />}
                                                                label={"Monday"}
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox defaultChecked onClick={() => onChange("tuesday")} />}
                                                                label={"Tuesday"}
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox defaultChecked onClick={() => onChange("wednesday")} />}
                                                                label={"Wednesday"}
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox defaultChecked onClick={() => onChange("thursday")} />}
                                                                label={"Thursday"}
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox defaultChecked onClick={() => onChange("friday")} />}
                                                                label={"Friday"}
                                                            />
                                                            <FormControlLabel control={<Checkbox onClick={() => onChange("saturday")} />} label={"Saturday"} />
                                                            <FormControlLabel control={<Checkbox onClick={() => onChange("sunday")} />} label={"Sunday"} />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl>
                                                        <FormLabel id="branch">Start day of work week</FormLabel>
                                                        <RadioGroup aria-labelledby="branch" name="branch" value={firstDay} onChange={firstDayHandleChange}>
                                                            {days.map((e, i) => (
                                                                <FormControlLabel key={i} value={i + 1} control={<Radio />} label={e} />
                                                            ))}
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className="l-page">
                                        <Grid item xs={12}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                className="float-end"
                                                onClick={submit}
                                                disabled={waiting}
                                                startIcon={waiting && <CircularProgress size="0.9rem" />}
                                            >
                                                Next
                                            </Button>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
