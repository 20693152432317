/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { toast } from "react-toastify";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import appURL from "../assets/requesterURL";
import loginURL from "../assets/loginAPI";
import REACT_APP from "../environment.js";
import getCookie from "../assets/cookie/getCookie.jsx";
import { useEffect } from "react";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {"Copyright © "}
            <Link color="inherit" to={REACT_APP.TRANSOXANIA}>
                transoxania.uz
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const defaultTheme = createTheme();

export default function SignInSide() {
    const [companyID, setCompanyID] = React.useState(getCookie("lc"));
    const [loading, setLoading] = React.useState(false);
    const [src, setSrc] = React.useState(null);
    const validateSignIn = Yup.object({
        email: Yup.string().required("Id is required"),
        password: Yup.string().required("Password is required"),
    });

    const [visibleIcon, setVisibleIcon] = React.useState(true);
    const handleSubmit = (event) => {
        const url = localStorage.getItem("url");
        const reply = appURL(url);
        setLoading(true);
        axios
            .post(`${loginURL(reply)}`, { employee_id: event.email, password: event.password })
            .then((res) => {
                setLoading(false);
                window.onbeforeunload = {};
                localStorage.setItem("token", res.data?.data?.token);
                localStorage.removeItem("url");
                window.location.replace(`${reply}?user=${res.data?.data?.token}`);
            })
            .catch((err) => {
                setLoading(false);
                toast.error((err.response?.data?.errors && err.response?.data?.errors[0]?.message) || "Something went wrong");
            });
    };

    async function getLogo() {
        if (companyID)
            await fetch(`${REACT_APP.API_URL}/api/hrp/v2/employee/logo/${companyID}`, { method: "GET" })
                .then((res) => res.blob())
                .then((blob) => {
                    if (blob.type?.slice(0, 5) === "image") {
                        setSrc(window.URL.createObjectURL(blob));
                    }
                })
                .catch((err) => console.log(err));
    }
    useEffect(() => {
        setCompanyID(getCookie("lc"));
        getLogo();
    }, [companyID]);

    return (
        <ThemeProvider theme={defaultTheme}>
            {loading && <div className="hrp-overlay"></div>}
            <Grid container component="main" sx={{ height: "100vh" }}>
                <CssBaseline />
                {src ? (
                    <div className="login-logo-container">
                        <div className="li-div">
                            <img alt="logo" src={src} id="l-image" height={300} />
                        </div>
                    </div>
                ) : (
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={7}
                        sx={{
                            backgroundImage: "url(https://source.unsplash.com/random?wallpapers)",
                            backgroundRepeat: "no-repeat",
                            backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    />
                )}
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Log In
                        </Typography>

                        <Formik
                            initialValues={{ email: "", password: "" }}
                            validationSchema={validateSignIn}
                            onSubmit={async (values) => {
                                handleSubmit(values);
                                return new Promise((res) => setTimeout(res, 500));
                            }}
                        >
                            {({ values, errors }) => (
                                <Form autoComplete="off" className="mt-4 p-3">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Field
                                                fullWidth
                                                name="email"
                                                label="ID"
                                                InputLabelProps={{ shrink: true }}
                                                autoComplete="email"
                                                component={TextField}
                                            />
                                        </Grid>

                                        <Grid item xs={12} className="input_div">
                                            <Field
                                                fullWidth
                                                label="Password"
                                                InputLabelProps={{ shrink: true }}
                                                name="password"
                                                component={TextField}
                                                autoComplete="password"
                                                type={visibleIcon ? "password" : "text"}
                                            />
                                            {visibleIcon ? (
                                                <VisibilityOffIcon onClick={() => setVisibleIcon((prev) => !prev)} />
                                            ) : (
                                                <RemoveRedEyeIcon onClick={() => setVisibleIcon((prev) => !prev)} />
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Button type="submit" fullWidth className="mt-4" variant="outlined" sx={{ mt: 2, mb: 2 }}>
                                        {loading ? (
                                            <Typography component="h4" variant="h6">
                                                Loading...
                                            </Typography>
                                        ) : (
                                            <Typography component="h4" variant="h6">
                                                Log In
                                            </Typography>
                                        )}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                        <Copyright sx={{ mt: 5 }} />
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
