/* eslint-disable no-useless-escape */
import regex from "tts-hrp-val-lib";
import { object, string } from "yup";

export const personalSchema = object({
    first_name: string().matches(regex.first_name, { message: "Invalid first name" }).required("First name is required"),
    last_name: string().matches(regex.last_name, { message: "Invalid last name" }).required("Last name is required"),
    middle_name: string().matches(regex.middle_name, { message: "Invalid middle name" }).nullable(),
    birth_date: string().required("Birth date is required"),
    tin: string().matches(regex.TIN, { message: "Invalid TIN number" }).nullable(),
    gender: string().required("Gender is required"),
    nationality: string().matches(regex.nationality, { message: "Invalid nationality" }).nullable(),
    citizenship: string().matches(regex.citizenship, { message: "Invalid citizenship" }).required("Citizenship is required"),
    home_phone: string().matches(regex.phone, { message: "Invalid phone number" }).required("Home phone is required"),
    cell_phone: string().matches(regex.phone, { message: "Invalid phone number" }).required("Cell phone is required"),
    password: string().required("Password is required"),
    cpassword: string().required("Confirm Password is required"),
});
