import React from "react";
import Index from "../components/FirstTimeCompany/Index";

const FirstTimeCompany = () => {
    return (
        <>
            <Index />
        </>
    );
};

export default FirstTimeCompany;
