import REACT_APP from "../environment.js";

export default function loginWithTokenURL(requester) {
    const employee = `${REACT_APP.API_URL}/api/hrp/v2/employee/login/token`;
    const admin = `${REACT_APP.API_URL}/api/hrp/v2/admin/login/token`;
    const timesheet = `${REACT_APP.API_URL}/api/hrp/v2/employee/login/timesheet/token`;

    /* localhost uchun */
    if (/localhost:9999/.test(requester)) return employee;
    if (/localhost:9998/.test(requester)) return admin;
    if (/localhost:9997/.test(requester)) return timesheet;

    /* live server uchun */
    if (/127.0.0.1:5500/.test(requester)) return employee;

    /* dev-environment uchun */
    if (/143.244.173.81:9001/.test(requester)) return admin;
    if (/143.244.173.81:9002/.test(requester)) return timesheet;
    if (/143.244.173.81:9003/.test(requester)) return employee;

    /* qa-environment uchun */
    if (/104.248.193.89:9001/.test(requester)) return admin;
    if (/104.248.193.89:9002/.test(requester)) return timesheet;
    if (/104.248.193.89:9003/.test(requester)) return employee;
    if (/admin-qa.ishkuni.uz/.test(requester)) return admin;
    if (/tms-qa.ishkuni.uz/.test(requester)) return timesheet;
    if (/hrp-qa.ishkuni.uz/.test(requester)) return employee;

    /* prod-environment uchun */
    if (/159.223.217.246:9201/.test(requester)) return admin;
    if (/159.223.217.246:9202/.test(requester)) return timesheet;
    if (/159.223.217.246:9203/.test(requester)) return employee;
    if (/admin.ishkuni.uz/.test(requester)) return admin;
    if (/tms.ishkuni.uz/.test(requester)) return timesheet;
    if (/hrp.ishkuni.uz/.test(requester)) return employee;
    return employee;
}
