/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import REACT_APP from "../../environment.js";

const CompanyInfo = ({ info, step }) => {
    const [logo, setlogo] = useState("");
    useEffect(() => {
        getLogo();
    }, []);
    function getLogo() {
        fetch(`${REACT_APP.HRP_DOC_API}/file/${info.logo}`, {
            method: "GET",
            headers: {
                "client-id": REACT_APP.CLIENT_ID,
                secret: REACT_APP.SECRET,
            },
        })
            .then((res) => res.blob())
            .then((blob) => {
                setlogo(window.URL.createObjectURL(blob));
            })
            .catch((err) => {
                console.log(err);
            });
    }
    return (
        <>
            <div className={step === "finish" ? "info-ci" : "info-ci not-finish"}>
                <div className="info-ci-1">
                    <div className="i-name">{info.name}</div>
                    <div className="i-info">
                        <div className="ii-item">Email: {info.email}</div>
                        <div className="ii-item">Phone: {info.phone}</div>
                        {info.website && <div className="ii-item">Website: {info.website}</div>}
                        {info.founder && <div className="ii-item">Founder: {info.founder}</div>}
                        {info.employee_id && <div className="ii-item">ID of Employees: {info.employee_id.slice(0, 2)}XXXX</div>}
                        {info.address && (
                            <div className="ii-item">
                                Address: {info.address.city}, {info.address.region}
                            </div>
                        )}
                    </div>
                </div>
                <div className="li-div">{!!logo && info.logo && <img alt="logo" src={logo} id="l-image" height={300} />}</div>
            </div>
        </>
    );
};

export default CompanyInfo;
