import { Button, Card, CardContent, CircularProgress, Grid } from "@mui/material";
import CompanyInfo from "../CompanyInfo";
import { useState } from "react";
import axios from "axios";
import REACT_APP from "../../../environment.js";
import { toast } from "react-toastify";

export default function Logo({ setStep, companyInfo, token }) {
    const [submitting, setSubmitting] = useState(false);
    const [file, setFile] = useState("");

    function submit() {
        setSubmitting(true);
        const data = new FormData();
        data.append("file", file);
        axios
            .post(`${REACT_APP.API_URL}/api/hrp/v2/company/first/logo`, data, { headers: { Authorization: token } })
            .then(() => {
                setStep("currency");
                setSubmitting(false);
            })
            .catch((err) => {
                setSubmitting(false);
                console.log(err);
                toast.error("An error occurred!");
            });
    }

    function skip() {
        setSubmitting(true);
        axios
            .post(`${REACT_APP.API_URL}/api/hrp/v2/company/first/logo/skip`, {}, { headers: { Authorization: token } })
            .then(() => {
                setStep("currency");
                setSubmitting(false);
            })
            .catch((err) => {
                setSubmitting(false);
                console.log(err);
                toast.error("An error occurred!");
            });
    }

    return (
        <>
            <div className="d-flex justify-content-center flex-column container py-3">
                <div className="py-2">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="text-center">Logo</h5>
                        </div>

                        <div>
                            <Card>
                                <CardContent style={{ padding: "24px" }}>
                                    <CompanyInfo info={companyInfo} />
                                    <div>
                                        <span htmlFor="file">Choose logo to upload</span>
                                        <Grid item container spacing={2} xs={12}>
                                            <Grid item xs={12} sm={4}>
                                                <Button type="submit" variant="contained" disabled={submitting}>
                                                    Choose
                                                    <input
                                                        disabled={submitting}
                                                        type="file"
                                                        id="il-file"
                                                        name="file"
                                                        accept="image/*"
                                                        onChange={(e) => {
                                                            setFile(e.target.files[0]);
                                                        }}
                                                    />
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm={2}></Grid>
                                            <Grid item xs={12} sm={6}>
                                                <div className="li-div">
                                                    {!!file && <img alt="logo" src={window.URL.createObjectURL(file)} id="l-image" height={300} />}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className="l-page">
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" className="float-end" disabled={!file || submitting} onClick={submit}>
                                                {submitting && <CircularProgress size="0.9rem" />}
                                                Next
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="button" variant="contained" className="float-start" onClick={skip}>
                                                Skip
                                            </Button>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
