import { Button, Card, CardContent, Grid } from "@mui/material";
import CompanyInfo from "../CompanyInfo";
import axios from "axios";
import REACT_APP from "../../../environment.js";
import { toast } from "react-toastify";

export default function Finish({ companyInfo, token }) {
    function submit() {
        axios
            .patch(`${REACT_APP.API_URL}/api/hrp/v2/company/first/ready`, {}, { headers: { Authorization: token } })
            .then(() => {
                window.onbeforeunload = {};
                window.location = "/login";
            })
            .catch((err) => {
                console.log(err);
                toast.error("An error occurred!");
            });
    }
    return (
        <>
            <div className="d-flex justify-content-center flex-column container py-3">
                <div className="py-2">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="text-center">Done</h5>
                        </div>

                        <div>
                            <Card>
                                <CardContent style={{ padding: "24px" }}>
                                    <CompanyInfo info={companyInfo} step="finish" />
                                    <div className="l-page">
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" onClick={submit} className="float-end">
                                                get started
                                            </Button>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
