import React from "react";

export default function Retry() {
    const style = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        flexDirection: "column",
    };
    console.log(window.location.href);
    const clickHandler = () => {
        window.onbeforeunload = {};
        let token = localStorage.getItem("ftc");
        let url = `${window.location.href}?company=${token}`;
        window.location.replace(url);
    };

    return (
        <div style={style}>
            <h6>Something went wrong</h6>
            <button type="button" className="btn btn-primary" onClick={clickHandler}>
                Retry
            </button>
        </div>
    );
}
