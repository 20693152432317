const NODE_ENV = process.env.REACT_APP_ENV || "development";

const REACT_APP = {
    development: {
        ADMIN_UI: "http://143.244.173.81:9101",
        TRANSOXANIA: "https://www.transoxania.uz",
        API_URL: "http://188.166.84.222:9100",
        HRP_DOC_API: "http://159.223.205.127:9103",
        CLIENT_ID: "398970e4cfa7449eb02f68c7d181412343e4b82f838b5aa72691584e958c41c2",
        SECRET: "1~BNt'[TN!1|%1W71*54>=S9&^AYaG(k,}'B6C8),Yj=zMkeCdHu8[H\"|GBE;'du",
        COMMON_API: "https://tts-common-api.herokuapp.com",
        COMMON_ID: "74a7a67458d77c3699a2db5132df8506afea37b31081987585d74724db98c1eb",
        COMMON_SECRET: 'I."b^34+Q>X\\"bHW.^9,6~=PtZsOn5F49kdWolPIlS=BW}9@N\\DGRxA9gNeHI}q{',
    },
    qa: {
        ADMIN_UI: "https://admin-qa.ishkuni.uz",
        TRANSOXANIA: "https://www.transoxania.uz",
        API_URL: "https://api1-qa.ishkuni.uz",
        HRP_DOC_API: "https://api3-qa.ishkuni.uz",
        CLIENT_ID: "398970e4cfa7449eb02f68c7d181412343e4b82f838b5aa72691584e958c41c2",
        SECRET: "1~BNt'[TN!1|%1W71*54>=S9&^AYaG(k,}'B6C8),Yj=zMkeCdHu8[H\"|GBE;'du",
        COMMON_API: "https://tts-common-api.herokuapp.com",
        COMMON_ID: "74a7a67458d77c3699a2db5132df8506afea37b31081987585d74724db98c1eb",
        COMMON_SECRET: 'I."b^34+Q>X\\"bHW.^9,6~=PtZsOn5F49kdWolPIlS=BW}9@N\\DGRxA9gNeHI}q{',
    },
    production: {
        ADMIN_UI: "https://admin.ishkuni.uz",
        TRANSOXANIA: "https://www.transoxania.uz",
        API_URL: "https://api.ishkunim.uz",
        HRP_DOC_API: "https://api3.ishkuni.uz",
        CLIENT_ID: "398970e4cfa7449eb02f68c7d181412343e4b82f838b5aa72691584e958c41c2",
        SECRET: "1~BNt'[TN!1|%1W71*54>=S9&^AYaG(k,}'B6C8),Yj=zMkeCdHu8[H\"|GBE;'du",
        COMMON_API: "https://tts-common-api.herokuapp.com",
        COMMON_ID: "74a7a67458d77c3699a2db5132df8506afea37b31081987585d74724db98c1eb",
        COMMON_SECRET: 'I."b^34+Q>X\\"bHW.^9,6~=PtZsOn5F49kdWolPIlS=BW}9@N\\DGRxA9gNeHI}q{',
    },
};

export default REACT_APP[NODE_ENV];
